/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials {

    .testimonials-carousel,
    .testimonials-slider {
        overflow: hidden;
    }

    .testimonials-slider {
        .testimonial-wrap {
            .testimonial-item {
                box-sizing: content-box;
                padding: 30px;
                margin: 30px 15px;
                min-height: 200px;
                box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
                position: relative;
                background: rgba(255, 255, 255, 1);
                border-radius: 15px;

                .testimonial-img {
                    width: 64px;
                    height: 64px;
                    border-radius: 10px;
                    border: 6px solid rgba(255, 255, 255, 1);
                    float: left;
                    margin: 0 10px 0 0;
                }

                h3 {
                    font-size: 18px;
                    font-weight: bold;
                    margin: 25px 0 5px 0;
                    color: #111;
                }

                h4 {
                    font-size: 14px;
                    color: #999;
                    margin: 0;
                }

                .quote-icon-left,
                .quote-icon-right {
                    color: #fceaea;
                    font-size: 26px;
                }

                .quote-icon-left {
                    display: inline-block;
                    left: -5px;
                    position: relative;
                }

                .quote-icon-right {
                    display: inline-block;
                    right: -5px;
                    position: relative;
                    top: 10px;
                }

                p {
                    font-style: italic;
                    margin: 30px auto 15px auto;
                }
            }
        }

        .swiper-pagination {
            margin-top: 20px;
            position: relative;

            .swiper-pagination-bullet {
                width: 12px;
                height: 12px;
                background-color: rgba(255, 255, 255, 1);
                opacity: 1;
                border: 1px solid var(--oksijen-turuncu);
            }

            .swiper-pagination-bullet-active {
                background-color: var(--oksijen-turuncu);
            }
        }
    }
}
